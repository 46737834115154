
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車・AMG】高い人気を誇るCクラス・CLAクラスの魅力を徹底解説
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            高級感溢れる内外装と高いパフォーマンスを発揮するメルセデスベンツの中でも、日本人に合ったコンパクトボディが特徴のCクラスは、中古車市場でも高い人気を誇ります。高性能なAMGモデルもおすすめです。
            <div class="section-title">
              <span >メルセデスベンツCクラス・CLAクラスの魅力とは？</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >メルセデスベンツには様々なクラスがありますが、日本で人気のクラスの一つに「Cクラス」があります。</p>
                    <p >こちらでは、そんなCクラスと、Cクラスと同等の見た目をしていながらも費用が安いCLAクラスそれぞれの魅力や人気モデルについてご紹介します。メルセデスベンツのCクラス・CLAクラスを選ぶなら、高パフォーマンスなAMGモデルがおすすめです。</p>
                    <h3 class="heading-number" data-number="◆">Cクラスの魅力</h3>  
                    <p >日本で最も売れているといわれるCクラスは、日本人に合ったコンパクトボディが特徴です。海外に比べて道幅の狭い日本でも快適な走行が可能であり、Sクラスに採用されている多くの安全機能が受け継がれているのも選ばれている理由の一つです。</p>
                    <p >また、エレガントで洗練されたデザイン性の高さも魅力で、車内は質感の高い素材で装飾され、豪華な雰囲気を醸し出しています。また、快適性にも配慮されています。座席はサポート力があり、乗り心地も滑らかです。</p>
                    <p >Cクラスは、都会の走行から高速道路での長距離ドライブまで、幅広いシーンで快適かつ楽しいドライビングを実現するために設計されています。高級感とスポーティーな性能を兼ね備えたCクラスは、メルセデスベンツの人気モデルの一つです。</p>
                    <p><b style="color: #c49a00;">【Cクラスの人気モデル】</b></p>
                    <ul style="margin-top: 0;">
                      <li>C180</li>
                      <li>C180アバンギャルド</li>
                      <li>C200アバンギャルド</li>
                      <li>C220dアバンギャルド</li>
                      <li>C250スポーツ</li>
                      <li>メルセデスAMG C63</li>
                    </ul>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/mercedes-benz-amg-cla-in-car.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">CLAクラスの特徴</h3>  
              <p >CLAクラスとはAクラスをベースに4ドアクーペをイメージしたモデルで、スタイリッシュなデザインが特徴です。クーペの中では比較的コンパクトなボディサイズでありながら、高い居住性と低重心なボディ設計から生み出されるダイナミックな走りも魅力の一つです。</p>
              <p >さらに、人工知能を搭載した「MBUXシステム」やSクラスに劣らない最高水準の安全システムなどの機能が搭載されています。標準装備であるMBUX（メルセデスベンツ ユーザー エクスペリエンス）は、人工知能による様々な機能を持つサポートシステムです。対話を繰り返すうちにオーナーの好みや傾向を分析し、動作の最適化を行うようにプログラムされています。</p>
              <p >また、車の自動運転を追求したシステム「インテリジェントドライブ」機能を搭載しており、車線変更サポートや注意力低下アシストなどの機能により、安全性能を高めています。「インテリジェントドライブ」はオプションでの選択になりますがAMGモデルには標準装備されています。</p>
              <p class="u-m0 u-mt20">
                <b style="color: #c49a00;">【CLAクラスの人気モデル】</b></p>
              <ul style="margin-top: 0;">
                <li>CLA180</li>
                <li>CLA200</li>
                <li>メルセデスAMG 35 4マチック</li>
                <li>メルセデスAMG CLA 45 S 4マチック＋</li>
              </ul>
              <h3 class="heading-number" data-number="◆">Cクラスを選ぶならAMGモデル「C63」がおすすめ</h3>
              <p >Cクラスのメルセデス車を選ぶなら、メルセデスベンツのサブブランドであるメルセデスAMGがチューニング・カスタムした「C63」がおすすめです。</p>
              <p >C63は、各種セーフティ装置が搭載されており、高い安全性が特徴です。車線変更アシスト、車線逸脱防止機能、追従走行サポート機能などを搭載しています。さらに、360度カメラを装備しており、周囲の状況を映像により確認できますので、安全な駐車が可能です。</p>
              <p >モータースポーツを彷彿とさせるようなエクステリアデザインと厳選した上質な素材を採用したスポーティーなインテリアもC63の魅力です。</p>
              <div class="c-btn">
                <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >car shop MIXでは市場に出回らない希少な中古車も販売！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >日本で最も売れているとされる、安全性と高性能な走りを両立したAMGモデルがおすすめです。Cクラス・CLAクラスともに、日本国内でも走りやすいコンパクトなボディサイズでありながら、快適な車内空間とダイナミックな走りを実現しています。</p>
                    <p >Cクラスの中でも、さらに高い安全性能をプラスしたC63が多くの人に選ばれています。ほかのクラスに比べて低価格な値段設定とは思えないほど、Sクラスに見劣りしないエクステリアとインテリアデザインもC63の魅力です。</p>
                    <p >car shop MIXでは、一般市場ではなかなか見つからない中古の限定車、希少車、旧車を中心に様々な車種を取り扱っております。在庫一覧にない車種でも、オークションを代行してお探しすることも可能です。</p>
                    <p >国産車・外車問わず中古車をお探しの方、特にレアな車種・マニュアル車・プレミアムカーをお探しの方は、car shop MIXにぜひ一度ご相談ください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="希少な中古車をご案内" src="@/assets/img/column-gtr-amg/rarity-used-car-guide.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'AMGモデル「C63」やAMG CLAなどの中古車をお探しならcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv06.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
